import React, { useState } from "react"

import {
  Vk,
  Telegram,
  Whatsapp,
  Viber,
  Odnoklassniki,
  Instagram,
  Facebook,
} from "@icons-pack/react-simple-icons"

import { ClipboardCopyIcon, ClipboardCheckIcon } from "@heroicons/react/outline"

export default function Share({ slug, text }) {
  const link = `https://katalikauto.ru${slug}`
  const [isCopied, setCopied] = useState(false)
  return (
    <div className="flex flex-col items-center py-2 text-center md:mb-0 md:w-1/2 md:items-start md:text-left lg:flex-grow ">
      <div className="max-w-1/2 flex w-full flex-row justify-start rounded-md border border-gray-300">
        <input
          defaultValue={link}
          className="m-0 w-full rounded-l-md border-l-4 border-corp-orange bg-gray-100 px-4 focus:outline-none"
          placeholder="Ссылка на катализатор"
          type="text"
          readonly
          disabled
        />
        <button
          onClick={() => {
            navigator.clipboard.writeText(link)
            setCopied(true)
          }}
          className="m-0 inline-flex h-12 items-center rounded-r-md border-l border-gray-300 bg-gray-100 py-2 px-6 text-lg text-black hover:bg-gray-200 focus:outline-none"
        >
          {isCopied ? (
            <>
              Скопировано
              <ClipboardCheckIcon className="ml-1 h-6 w-6" />
            </>
          ) : (
            <>
              Копировать
              <ClipboardCopyIcon className="ml-1 h-6 w-6" />
            </>
          )}
        </button>
      </div>
      <p className="mt-2 mb-4 w-full text-sm text-gray-500">
        Поделиться ссылкой в социальных сетях
      </p>
      <div className="flex md:flex-col lg:flex-row">
        <a
          href={`whatsapp://send?text=${text}:${slug}`}
          data-action="share/whatsapp/share"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2 mb-6 bg-transparent text-[#25D366]"
        >
          <Whatsapp className="h-6 w-6 lg:h-8 lg:w-8" />
        </a>
        <a
          href={`viber://forward?text=${text}:${slug}`}
          data-action="share/viber/share"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2 mb-6 bg-transparent text-[#7360F2]"
        >
          <Viber className="h-6 w-6 lg:h-8 lg:w-8" />
        </a>
        <a
          href={`https://telegram.me/share/url?url=${link}&text=${text}`}
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2 mb-6 bg-transparent text-[#26A5E4]"
        >
          <Telegram className="h-6 w-6 lg:h-8 lg:w-8" />
        </a>
        <a
          href={`https://vk.com/share.php?url=${link}`}
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2 mb-6 bg-transparent text-[#0077FF]"
        >
          <Vk className="h-6 w-6 lg:h-8 lg:w-8" />
        </a>
        <a
          href={`https://connect.ok.ru/offer?url=${link}`}
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2 mb-6 bg-transparent text-[#EE8208]"
        >
          <Odnoklassniki className="h-6 w-6 lg:h-8 lg:w-8" />
        </a>
        <a
          href={`https://www.instagram.com/?url=${link}`}
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2 mb-6 bg-transparent text-[#E4405F]"
        >
          <Instagram className="h-6 w-6 lg:h-8 lg:w-8" />
        </a>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2 mb-6 bg-transparent text-[#1877F2]"
        >
          <Facebook className="h-6 w-6 lg:h-8 lg:w-8" />
        </a>
      </div>
    </div>
  )
}
