import { useStaticQuery, graphql } from "gatsby"

function useMetalPrices() {
  const metals = useStaticQuery(graphql`
    query {
      russia {
        name
        price
      }
      imp {
        name
        price
      }
      bmw {
        name
        price
      }
      lanos {
        name
        price
      }
      kom {
        name
        price
      }
    }
  `)

  return metals
}

export default useMetalPrices
