import React, { useEffect } from "react"

import { Link, navigate } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

import { SearchIcon } from "@heroicons/react/solid"

import { useRecoilState, useRecoilValue } from "recoil"
import {
  visitHistoryAtom,
  searchHistoryAtom,
  articlesQueryAtom,
} from "../atoms"

import { convertToSlug } from "../helpers"
import useArticlesImages from "../../hooks/useArticlesImages"

export default function OpenHistory() {
  const [query, setQuery] = useRecoilState(articlesQueryAtom)
  const visitHistory = useRecoilValue(visitHistoryAtom)
  const [searchHistory, setSearchHistory] = useRecoilState(searchHistoryAtom)

  useEffect(() => {
    let lastItem =
      searchHistory.lenght !== 0
        ? searchHistory[searchHistory.length - 1]
        : null

    if (lastItem && query !== "" && query.startsWith(lastItem)) {
      let newArr = [...searchHistory]
      newArr[newArr.length - 1] = query
      setSearchHistory(newArr)
    } else if (lastItem && lastItem.startsWith(query)) {
      // console.log("do nothing...")
    } else setSearchHistory(searchHistory => [...searchHistory, query])
  }, [query])

  const images = useArticlesImages()
  const nodes = images.allFile.edges

  const Image = ({ entry }) => {
    const { brand, catalystNumber, img } = entry
    const imageName = img?.split("/").pop().split(".")[0]

    const filtered = nodes.find(node => node.node.name === imageName)
    const pathToImage = getImage(filtered?.node)

    if (!filtered)
      return (
        <div className="ml-2 flex-shrink-0 justify-self-center rounded-sm bg-white sm:ml-0">
          <StaticImage
            src="../../assets/images/placeholder.svg"
            alt={`Катализатор от ${brand} ${catalystNumber}`}
            className="h-24 w-36"
          />
        </div>
      )

    return (
      <div className="flex-shrink-0 justify-self-center rounded-sm">
        <GatsbyImage
          image={pathToImage}
          alt={`Катализатор от ${brand} ${catalystNumber}`}
          className="h-24 w-36"
        />
      </div>
    )
  }

  return (
    <div className="border-t border-gray-300 bg-gray-100 py-4">
      <div className="mx-auto grid max-w-xl grid-cols-1 gap-x-4 px-2 md:px-12 lg:max-w-7xl lg:grid-cols-2">
        <div className="p-2">
          <h3 className="pb-2 text-left text-xl font-semibold md:text-3xl">
            Недавно просмотренные
          </h3>

          {[...visitHistory].reverse().map((record, index) => {
            return index < 7 ? (
              <Link
                to={`/articles/${convertToSlug(record?.catalystNumber)}`}
                key={`visit-history-${index}`}
                className="my-2 grid grid-cols-3 overflow-x-hidden rounded-md bg-gray-50 p-2 drop-shadow-md hover:bg-white hover:drop-shadow-xl"
              >
                <Image entry={record} />
                <div className="cols-span-2 ml-6 px-2 text-xs sm:ml-0 sm:text-base">
                  <div className="text-gray-800">
                    <span className="font-semibold text-gray-600">Марка:</span>{" "}
                    {record.brand}
                  </div>

                  <span className="font-semibold text-gray-600 ">Артикул:</span>
                  <p className="w-36 break-all text-gray-800 lg:w-max">
                    {record?.catalystNumber}
                  </p>
                </div>
              </Link>
            ) : null
          })}
        </div>

        <div className="p-2">
          <h3 className="pb-2 text-left text-xl font-semibold md:text-3xl">
            Ваши предыдущие поиски
          </h3>
          <div className="grid grid-cols-1 gap-y-1 ">
            {[...searchHistory].reverse().map((record, index) => {
              return index < 10 ? (
                <div
                  onClick={() => {
                    setQuery(record)
                    navigate(`/articles?q=${record.replaceAll(" ", "+")}`)
                  }}
                  className="cursor-pointer underline decoration-corp-orange underline-offset-2"
                  key={`search-history-${index}`}
                >
                  <div className="inline-flex items-center text-gray-800 ">
                    <SearchIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    {record}
                  </div>
                </div>
              ) : null
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
